/* =============demoAPI======================== */

.analyzer {
  height: 25%;
  padding-left: 0;
  display: block;
}

.analyze_main_container {
  height: unset;
  width: 100%;
  color: white;
  z-index: 2;
  position: inherit;
  top: inherit;
  margin: auto;
}
#influencerDiscoveryForm .autocomplete-items {
  position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 1001;
    color: #545454;
    margin:0 0;
    left: 0;
    right: 0;
    max-height: 150px;
    overflow-y: auto;
}
#influencerDiscoveryForm .autocomplete-active {
  background-color: #00dafc !important;
  color: #ffffff;
}
#influencerDiscoveryForm .autocomplete-items div {
  padding: 5px 2px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: .05px solid #d4d4d4;
    text-align: center;
}
.influencer-Discovery-Form #influencerDiscoveryForm .autocomplete-items {
  color: #fff;
}



#influencerDiscoveryForm .autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #00dafc;
    border-bottom: 1px solid rgba(255,255,255,.5);
}
.font-size12{
  font-size: 12px;
}
.analyze_main_container {
  height: unset;
  width: 100%;
  color: white;
  z-index: 2;
  position: inherit;
  top: inherit;
  margin: auto;
}

.fh5co-cover .analyze_main_container h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  text-shadow: 1px 1px grey;
  margin-bottom: 10px;
  line-height: 60px;
  letter-spacing: 1px;
}

.fh5co-cover .analyze_main_container span {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-shadow: 1px 1px grey;
  letter-spacing: 1px;
  display: block;
}

#serverDownText {
  text-align: center;
  font-weight: 500;
  font-size: 2em;
}

#serverDownDiv {
  text-align: center;
}

#serverDown {
  margin: 0 auto;
  width: 30%;
}

@media (min-width:769px) {
  .analyze_main_container {
    padding-top: 48px
  }
}

@media screen and (max-width: 767px) {
  .fh5co-cover .analyze_main_container h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .fh5co-cover .analyze_main_container span {
    font-size: 14px;
  }
}

.analyzer .analzer-fakespot {
  padding-left: 0;
  display: inline-block;
  margin-left: 0;
  margin-right: 7px;
  padding-bottom: 4px;
  padding-left: 25px;
}

.analyzer .analzer-fakespot {
  padding-left: 0;
  display: inline-block;
  margin-left: 0;
  margin-right: 7px;
  padding-bottom: 4px;
  padding-left: 25px;
}

.analzer-fakespot img {
  vertical-align: middle;
}

.analyzer .analyzer-text {
  padding-left: 0;
  line-height: 18px;
  display: inline-block;
  color: #FFFFFF;
  letter-spacing: 2px;
  margin-top: 15px;
}

.analyzer .reviewsites {
  display: inline-block;
  margin-left: 380px;
  padding: 0;
  width: 150px;
  padding-top: 20px;
}

.analyzer .reviewsites img {
  width: 15px;
  height: 15px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-right: 15px;
}

.home .card {
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.5);
  display: inline-block;
  width: 50%;
}

.home .card .form-control.url-input-field {
  margin-top: 20px;
  width: 100%;
  font-size: 16px;
}

.url-input-field::placeholder {
  color: #555;
  font-weight: 400;
}

.home .card .submit-button {
  background-color: #34A2FF;
  border-color: #34A2FF;
  opacity: .9;
}
.home .card .submit-button:hover {
  opacity: 1;
}

.home .card .submit-button .spinner {
  margin-left: 15px;
}

.ludwil:before {
  content: '\201C';
  font-family: 'Sanchez';
  position: absolute;
  color: #4d91b3;
  top: -16px;
}

.analysis-select-btn {
  justify-content: space-between;
  padding: 12px 32px;
}

.padding-24px {
  padding: 0 24px;
}

.select-social-lable {
  color: #555;
  align-items: center;
  display: flex;
  font-size: 14px;
  padding: 0 12px;
  padding-left: 0;
  font-weight: 400;
}

select#selectSocialAnalyser.form-control {
  padding: 0 5px;
  border: none;
  box-shadow: none;
}

@media(max-width:480px) {
  .select-social-lable {
    margin-bottom: 0;
  }
  .home .card .submit-button {
    margin-top: 12px;
    padding: 8px 12px;
    background-color: #34A2FF;
    border-color: #34A2FF;
  }
}

.ludwir:after {
  content: '\201D';
  position: absolute;
  font-family: 'Sanchez';
  color: #4d91b3;
  top: -28px;
}

/* .quotes-on-truncateText {
  position: relative;
  font-size: 2.4em;
}

.quotes-on-truncateText:after {
  content: '\201D';
  font-family: 'Sanchez';
  position: absolute;
  color: #4d91b3;
}

.quotes-on-truncateText:before {
  content: '\201C';
  font-family: 'Sanchez';
  position: absolute;
  color: #4d91b3;
} */

.quotes-on-headingText {
  position: relative;
  padding-left: 1em;
  font-size: 22px;
  line-height: 1.4em;
  text-align: center;
  border-left: none;
}

.quotes-on-headingText:before,
.quotes-on-headingText:after {
  content: '\201C';
  font-family: 'Sanchez';
  color: #4d91b3;
  font-size: 26px;
}

.quotes-on-headingText:after {
  content: '\201D';
}

.ludwil {
  padding-left: 12px;
  position: relative;
  font-size: 2.4em;
  text-align: left;
}

.ludwir {
  padding-right: 24px;
  position: relative;
  font-size: 2.4em;
  text-align: right;
}

.home {
  padding: 24px 0;
}

.fh5co-cover .desc .paste-text ul li span {
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  color: #000;
}

.api-demo-box {
  justify-content: center;
  padding: 24px 0 96px;
}

.api-demo-box h4 {
  margin-bottom: 0
}

.align-center {
  align-items: center;
  display: flex;
}

@media(max-width:480px) {
  .home .card {
    width: 100%;
  }
  .home {
    padding: 48px 0;
  }
  .fh5co-hero .fh5co-cover .api-demo-box .btn {
    padding: 8px 12px;
    width: fit-content;
  }
  .api-demo-box h4 {
    margin-bottom: 12px
  }
}

/* =============demoAPI Answer======================== */

.nlp_answer {
  background: #fff;
}

.nlp_answer h5 {
  color: #fff;
  margin-bottom: 0
}

.nlp_answer ul {
  margin-bottom: 0;
}

.nlp_answer p {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  padding-top: 12px;
}

.nlp_answer span {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
}

.nlp_actionable_li {
  border-bottom: 1px solid #ccc;
  padding: 8px 0px;
}

.nlp_actionable_li:last-child {
  border-bottom: none;
}

.actionable_showsentiment {
  padding: 2px 5px;
  max-height: 24px;
  font-size: 12px !important;
  color: #fff !important;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 800 !important;
  white-space: nowrap;
  margin: 1px;
}

.backgroundPOSITIVE {
  background: #61bd4f !important;
}

.backgroundNEGATIVE {
  background: #eb5a46 !important;
}

.backgroundNEUTRAL {
  background: #0079bf !important;
}

.nlp_part_container {
  padding: 24px;
  flex-basis: 100%;
}

.nlp_main_container {
  padding: 0 10%;
}

.nlp_engage_border {
  padding: 12px;
  background: #fff;
  border-top: 1px solid #ccc;
}

.nlp_engage_border a {
  color: #0079c0;
  font-weight: 400
}

.nlp_contentText {
  background: #fff;
  border: 1px solid #ccc;
}

.nlp_contentMain {
  padding: 16px;
  background-color: #0079c0;
}

@media (max-width:480px) {
  .nlp_answer span {
    font-size: 14px;
    color: #000;
    font-weight: 400;
  }
  .nlp_actionable_li {
    padding: 5px 0;
  }
  .nlp_part_container {
    padding: 0;
    flex-basis: 100%;
  }
  .nlp_main_container {
    padding: 24px 5px;
  }
  .nlp_contentText {
    background: #fff;
  }
}

/* ======smily========== */

[class^="smiley-"] {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.smiley-green {
  background: #f2d600;
  border: 3px solid #000;
  position: relative;
}

.smiley-green .left-eye {
  width: 18%;
  height: 18%;
  background: #000;
  position: relative;
  top: 29%;
  left: 22%;
  border-radius: 50%;
  float: left;
}

.smiley-green .right-eye {
  width: 18%;
  height: 18%;
  border-radius: 50%;
  position: relative;
  background: #000;
  top: 29%;
  right: 22%;
  float: right;
}

.smiley-green .smile {
  position: absolute;
  top: 67%;
  left: 16.5%;
  width: 70%;
  height: 40%;
  overflow: hidden;
}

.smiley-green .smile:after,
.smiley-green .smile:before {
  content: "";
  position: absolute;
  top: -50%;
  left: 0%;
  border-radius: 50%;
  background: #000;
  height: 100%;
  width: 97%;
}

.smiley-green .smile:after {
  background: #000;
  height: 80%;
  top: -40%;
  left: 0%;
}

.smiley-yellow {
  background: #f2d600;
  border: 3px solid #000;
  position: relative;
}

.smiley-yellow .left-eye {
  width: 18%;
  height: 18%;
  background: #000;
  position: relative;
  top: 29%;
  left: 22%;
  border-radius: 50%;
  float: left;
}

.smiley-yellow .right-eye {
  width: 18%;
  height: 18%;
  border-radius: 50%;
  position: relative;
  background: #000;
  top: 29%;
  right: 22%;
  float: right;
}

.smiley-yellow .smile {
  position: absolute;
  top: 67%;
  left: 20%;
  width: 60%;
  height: 7%;
  background: #000;
  overflow: hidden;
  border-radius: 8px;
}

.smiley-red {
  background: #f2d600;
  border: 3px solid #000;
  position: relative;
}

.smiley-red .left-eye {
  width: 18%;
  height: 18%;
  background: #000;
  position: relative;
  top: 29%;
  left: 22%;
  border-radius: 50%;
  float: left;
}

.smiley-red .right-eye {
  width: 18%;
  height: 18%;
  border-radius: 50%;
  position: relative;
  background: #000;
  top: 29%;
  right: 22%;
  float: right;
}

.smiley-red .smile {
  position: absolute;
  top: 57%;
  left: 16.5%;
  width: 70%;
  height: 20%;
  overflow: hidden;
}

.smiley-red .smile:after,
.smiley-red .smile:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  border-radius: 50%;
  background: #000;
  height: 100%;
  width: 97%;
}

.smiley-red .smile:after {
  background: #000;
  height: 80%;
  top: 60%;
  left: 0%;
}